@import '~react-perfect-scrollbar/dist/css/styles.css';

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

/* html,
body {
	font-family: 'Roboto', sans-serif;
} */

html {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background-color: #f4f6f8;
	height: 100%;
}

a {
	text-decoration: none;
}

#root {
	height: 100%;
}

:not(pre) > code {
	font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
	background-color: #eeeeee;
	padding: 2px 4px;
	direction: ltr;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	line-height: 1.5;
	font-size: 14px;
}

/**
 * annoying yellow bg on autofilled inputs
 * @see https://github.com/mui-org/material-ui/issues/283
 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
	transition-delay: background-color 5000s, color 5000s;
}

/* ------- */

.centered {
	text-align: center;
}

hr {
	border-bottom: 1px solid #ddd;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	margin: 3em 0;
}

hr.base {
	margin: 1.5em 0;
}

.relative {
	position: relative;
}

.flex-grow {
	flex-grow: 1;
}

.mono{
	font-family: monospace, monospace;
}

.dashed-underline{
	display: inline;
	padding-bottom: 3px;
	border-bottom: .5px dashed #ccc;
	cursor: pointer;
}

.dashed-underline:hover,
.dashed-underline:focus,
.dashed-underline:active {
	border-bottom: .5px solid #bbb
}

/* Spacing */

.space-top {
	margin-top: 1.5em;
}
.space-top-large {
	margin-top: 3em;
}
.space-top-small {
	margin-top: 0.75em;
}
.space-top-smallest {
	margin-top: 0.5em;
}

.space-bottom {
	margin-bottom: 1.5em;
}
.space-bottom-large {
	margin-bottom: 3em;
}
.space-bottom-small {
	margin-bottom: 0.75em;
}
.space-bottom-smallest {
	margin-bottom: 0.5em;
}

.space-right {
	margin-right: 1.5em;
}
.space-right-large {
	margin-right: 3em;
}
.space-right-small {
	margin-right: 0.75em;
}
.space-right-smallest {
	margin-right: 0.5em;
}

.space-left {
	margin-left: 1.5em;
}
.space-left-large {
	margin-left: 3em;
}
.space-left-small {
	margin-left: 0.75em;
}
.space-left-smallest {
	margin-left: 0.5em;
}
